var processInclude = require('base/util');
var abSlider = require('./components/slider');
// This list should include any JS files that check for specific nodes in DOM that may be added to belowFold region
var coreFiles = {
    einstein: require('core/components/einsteinRecs'),
    productTile: require('core/components/productTile'),
    video: require('core/components/video'),
    slider: require('core/components/slider'),
    pageDesigner: require('core/pageDesigner')
}

$(document).ready(() => {

    // Render any components in the Below Fold region via ajax after page load
    if ($('#belowFoldRegion').length) {
        const $belowFoldRegion = $('#belowFoldRegion');
        const url = $belowFoldRegion.data('url');
        const populateRegion = new Promise(resolve => {
            // Send to Page-Show, return HTML for ajaxRegion template
            $.ajax({
                url: url,
                method: 'GET',
                success: response => {
                    $belowFoldRegion.html(response);
                    resolve();
                },
                error: err => {
                    console.error('Unable to render Below Fold region ', err);
                }
            });
        });

        populateRegion.then(() => {
            Object.keys(coreFiles).forEach(function (key) {
                if (key === 'slider') {
                    //coreFiles[key].initializeSliders($belowFoldRegion);
                    abSlider.initializeSliders($belowFoldRegion);
                } else {
                    processInclude(coreFiles[key]);
                }
            });
        });
    }

    // Delegating this event to body in case Content Grid component is loaded in Below Fold region
    $('body').on('click', '.show-more-content button', function (e) {
        e.stopPropagation();
        var $target = $(event.target);
        var showMoreUrl = $(this).data('url');
        e.preventDefault();
        $.spinner().start();

        $.ajax({
            url: showMoreUrl,
            data: { selectedUrl: showMoreUrl },
            method: 'GET',
            success: function (response) {
                $target.closest('.content-grid-footer').replaceWith(response);
                $.spinner().stop();
            },
            error: function () {
                $.spinner().stop();
            }
        });
    });

});
